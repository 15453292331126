<template>
  <div class="lb-banner">
    <img src="https://qncweb.ktvsky.com/20240910/vadd/f35d83caa9333332d728fd34a4f96bd4.png" alt="">
  </div>
</template>

<script>
// import { ref, watch, computed, onMounted } from 'vue'
// import { useStore } from 'vuex'
// import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'LbBanner',
  setup() {
    return {

    }
  }
}
</script>

<style lang="stylus" scoped>
.lb-banner
  position fixed
  bottom 50px
  left 80px
  width 854px
  height 346px
  // background url('https://qncweb.ktvsky.com/20240910/vadd/f35d83caa9333332d728fd34a4f96bd4.png') no-repeat
  // background-size 100% auto
  img
    width 100%
    height 100%
    @media screen and (max-width 1200px)
      display none
</style>
